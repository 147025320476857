import React from "react"

const SubNavCompact = ({ subNavigation }) => (
    <nav className="subnav subnav--compact" role="navigation">
        {displaySubNav(subNavigation)}
    </nav>
)

const displaySubNav = (subnav) => (
    <ul className="subnav__list">
        {subnav &&
            subnav.map(({ name, url, isSelected, links }, index) => (
                <li className={`subnav__item ${links.length > 0 ? "has-children" : ""} ${isSelected ? "active" : ""}`} key={`subnavCompact-${index}`}>
                    <a className="subnav__link" href={url}>
                        {name}
                    </a>
                    {displaySubNav(links)}
                </li>
            ))}
    </ul>
)

export default SubNavCompact
